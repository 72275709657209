import React, { useState, useEffect, useMemo } from 'react';
import { SVGIcon } from 'fq-ui';
import * as Styled from './App.style';

const { REACT_APP_US_LOGIN_URL, REACT_APP_EMEA_LOGIN_URL } = process.env;

const loginUrls = {
    us: REACT_APP_US_LOGIN_URL,
    emea: REACT_APP_EMEA_LOGIN_URL
};

const appNames = {
    floqademy: 'FloQademy',
    floverse: 'FloVerse'
};

function redirect(region) {
    const url = loginUrls[region];
    url && window.location.assign(url);
}

const getArrowSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.492" height="10.897">
        <path
            d="M22.492 5.624a4.411 4.411 0 0 1-.437.617q-2.169 2.18-4.357 4.341a.879.879 0 1 1-1.266-1.213q1.421-1.422 2.847-2.837c.055-.055.107-.113.194-.2H.99a.9.9 0 0 1-.951-.62.877.877 0 0 1 .776-1.14c.08-.006.161 0 .242 0h18.417c-.084-.089-.138-.149-.2-.206q-1.419-1.42-2.844-2.838a.863.863 0 0 1-.245-.9.83.83 0 0 1 .663-.6.876.876 0 0 1 .836.272q1.595 1.589 3.193 3.175c.395.392.8.776 1.178 1.182a5.317 5.317 0 0 1 .437.617Z"
            fill="#0f55d1"
        />
    </svg>
);

/** Hook to watch url and fire when search param changes */
const useLocationSearch = () => {
    const [search, setSearch] = React.useState(window.location.search);
    const listenToPopstate = () => {
        const winSearch = window.location.search;
        setSearch(winSearch);
    };
    useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return new URLSearchParams(search);
};

const getFaviconElement = () => document.getElementById('favicon');

function App() {
    const search = useLocationSearch();

    const [showFloverseContent, setShowFloverseContent] = useState(false);

    // update when on floverse query param
    useEffect(() => {
        // set params
        const onFloverse = search.get('from') === 'floverse';
        setShowFloverseContent(onFloverse);
    }, [search]);

    // dynamically set page content
    const pageContent = useMemo(() => {
        // update favicon
        const favicon = getFaviconElement();

        if (favicon) {
            favicon.href = showFloverseContent
                ? '/favicon-floverse.png'
                : '/favicon.png';
        }

        // set content
        return {
            headerLeftImage: showFloverseContent
                ? '/images/floverse-icon.svg'
                : '/images/header_image.svg',
            mainImg: showFloverseContent
                ? '/images/floverse-banner.svg'
                : '/images/floqademy-logo.svg',
            loginRegionHeader: showFloverseContent
                ? appNames.floverse
                : appNames.floqademy,
            footerLogo: {
                src: showFloverseContent
                    ? '/images/floverse-banner.svg'
                    : '/images/footer_logo.svg',
                alt: showFloverseContent
                    ? appNames.floverse
                    : appNames.floqademy
            }
        };
    }, [showFloverseContent]);

    return (
        <Styled.App>
            <Styled.GlobalStyle />
            <Styled.Header>
                <Styled.HeaderInner>
                    <Styled.HeaderLeftImage src={pageContent.headerLeftImage} />
                </Styled.HeaderInner>
            </Styled.Header>
            <Styled.SkilljarContent>
                <Styled.MainImg src={pageContent.mainImg} />
                <Styled.LoginRegionSelector column>
                    <Styled.LoginRegionHeader>
                        {`In order to login to ${pageContent.loginRegionHeader}, please select the
                        version of the FloQast app you use:`}
                    </Styled.LoginRegionHeader>
                    <Styled.ButtonContainer column>
                        <Styled.SelectButton
                            onClick={(e) => redirect('us')}
                            outerStyle={{ borderRadius: '10px' }}
                        >
                            floqast.app
                            <SVGIcon
                                svg={getArrowSvg()}
                                style={{ marginLeft: '10px' }}
                            />
                        </Styled.SelectButton>
                        <Styled.SelectButton
                            onClick={(e) => redirect('emea')}
                            outerStyle={{ borderRadius: '10px' }}
                        >
                            eu.floqast.app
                            <SVGIcon
                                svg={getArrowSvg()}
                                style={{ marginLeft: '10px' }}
                            />
                        </Styled.SelectButton>
                    </Styled.ButtonContainer>
                </Styled.LoginRegionSelector>
            </Styled.SkilljarContent>
            <Styled.FooterOuter>
                <Styled.FooterInner>
                    <Styled.FooterTop>
                        <Styled.FooterLogo
                            src={pageContent.footerLogo.src}
                            alt={pageContent.footerLogo.alt}
                        />
                        <Styled.FooterTagline>
                            Let's elevate accounting together.
                        </Styled.FooterTagline>
                    </Styled.FooterTop>
                    <Styled.FooterCenterLine
                        src="/images/footer_line.svg"
                        alt=""
                    />
                    <Styled.FooterBottom>
                        <Styled.FooterBottomLeft>
                            © {new Date().getFullYear()} FloQast. All rights
                            reserved.
                        </Styled.FooterBottomLeft>
                        <Styled.FooterBottomCenter>
                            <Styled.FooterBottomCenterTextItem>
                                <a
                                    href="https://floqast.com/terms-of-use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Use
                                </a>
                            </Styled.FooterBottomCenterTextItem>
                            <Styled.FooterBottomCenterTextItem>
                                I
                            </Styled.FooterBottomCenterTextItem>
                            <Styled.FooterBottomCenterTextItem>
                                <a
                                    href="https://floqast.com/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </Styled.FooterBottomCenterTextItem>
                        </Styled.FooterBottomCenter>
                        <Styled.FooterBottomRight>
                            <Styled.FooterBottomRightLink
                                href="https://www.linkedin.com/company/floqast/mycompany/"
                                target="_blank"
                            >
                                <img
                                    src="/images/linkedin.svg"
                                    alt="LinkedIn"
                                />
                            </Styled.FooterBottomRightLink>
                            <Styled.FooterBottomRightLink
                                href="https://www.facebook.com/floqast/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/images/facebook.svg"
                                    alt="Facebook"
                                />
                            </Styled.FooterBottomRightLink>
                            <Styled.FooterBottomRightLink
                                href="https://twitter.com/floqast"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/images/twitter.svg" alt="Twitter" />
                            </Styled.FooterBottomRightLink>
                            <Styled.FooterBottomRightLink
                                href="https://www.instagram.com/floqast/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/images/instagram.svg"
                                    alt="Instagram"
                                />
                            </Styled.FooterBottomRightLink>
                            <Styled.FooterBottomRightLink
                                href="https://podcasts.apple.com/us/podcast/id1483690305"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/images/podcast.svg" alt="Podcast" />
                            </Styled.FooterBottomRightLink>
                            <Styled.FooterBottomRightLink
                                href="https://www.youtube.com/channel/UCjgrhfNyjxtMOocyEWIhyFg"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src="/images/youtube.svg" alt="YouTube" />
                            </Styled.FooterBottomRightLink>
                        </Styled.FooterBottomRight>
                    </Styled.FooterBottom>
                </Styled.FooterInner>
            </Styled.FooterOuter>
        </Styled.App>
    );
}

export default App;
