import styled, { createGlobalStyle } from 'styled-components';
import { Flex, ButtonV2 as Button } from 'fq-ui';

export const GlobalStyle = createGlobalStyle`
    html {
        background-color: #0f55d1;
    }
    body {
        margin: 0;
        padding: 0;

        font-family: "proxima-nova", sans-serif;
        font-weight: 400;
        font-style: normal;
        height: 100%;
    }
`;

export const App = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 365px;
`;

export const Header = styled.header`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 66px;
    background-color: #0f55d1;

    @media only screen and (min-width: 768px) {
        height: 60px;
    }
`;

export const HeaderInner = styled.div`
    max-width: 1142px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media only screen and (min-width: 1213px) {
        width: 1196px;
    }
    @media only screen and (min-width: 926px) and (max-width: 1213px) {
        width: 909px;
    }
    @media only screen and (min-width: 801px) and (max-width: 926px) {
        width: 784px;
    }
    @media only screen and (min-width: 607px) and (max-width: 801px) {
        width: 590px;
    }
    @media only screen and (min-width: 545px) and (max-width: 607px) {
        width: 528px;
    }
    @media only screen and (max-width: 545px) {
        width: 324px;
    }
`;

export const HeaderLeftImage = styled.img`
    height: 35px;
    max-width: 100%;
    vertical-align: middle;

    @media only screen and (max-width: 607px) {
        height: 29px;
    }
`;

export const SkilljarContent = styled.div`
    background: #fff;
    width: 100%;
    min-height: 800px;
    justify-content: center;
    background-color: #0143AF;
    background-image: url('/images/FloQademy-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: relative;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 130px;
    gap: 0;
`;

export const MainImg = styled.img`
    display: block;
    width: 500px;
    max-width: calc(100% - 40px);
    min-width: 325px;
`;

export const FooterOuter = styled.footer`
    background-color: #0f55d1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #d5d5d5;
    width: 100%;
    padding: 15px 0 10px;
    cursor: default;
    box-sizing: border-box;
`;

export const FooterInner = styled.div`
    max-width: 1142px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 10px;

    a {
        color: #fff;
        text-decoration: none;
    }
`;

export const FooterTop = styled.div`
    display: flex;

    @media only screen and (min-width: 680px) {
        padding: 20px;
        flex-direction: row;
        justify-content: space-between;
    }
    @media only screen and (max-width: 680px) {
        padding: 10px 0;
        font-size: 14px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
export const FooterLogo = styled.img`
    display: block;
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 55px;
`;
export const FooterTagline = styled.h6`
    margin: 0;
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
    font-size: min(28px, max(2.4vw, 18px));
`;
export const FooterCenterLine = styled.img`
    display: block;
    max-width: 100%;
    min-height: 1px;
`;
export const FooterBottom = styled.div`
    display: flex;
    
    @media only screen and (min-width: 780px) {
        padding: 20px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    @media only screen and (max-width: 780px) {
        padding: 10px 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding-bottom: 10px;
    }
`;
export const FooterBottomLeft = styled.div`
    font-size: 14px;
`;
export const FooterBottomCenter = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const FooterBottomCenterTextItem = styled.div`
    padding: 0 5px;
    user-select: none;
`;
export const FooterBottomRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const FooterBottomRightLink = styled.a`
    display: block;
    color: #1694d1;
    padding: 0 10px;
`;

export const LoginRegionSelector = styled(Flex)`
    font-family: Helvetica, sans-serif;
    align-items: center;
    margin: 45px 0 130px;
`;
export const LoginRegionHeader = styled.h4`
    max-width: 260px;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    opacity: 0.95;
    font-family: "proxima nova", sans-serif;
    text-rendering: optimizeLegibility;
`;

export const ButtonContainer = styled(Flex)`
    justify-content: center;
    gap: 12px;
`;

export const SelectButton = styled(Button)`
    color: #0F6BDE;
    border-radius: 8px;
    border: 3px solid #9CD651;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-weight: 600;

    padding: 16px 12px;

    &:hover {
        color: #0F6BDE;
        background-color: #9CD651;
        border: 3px solid #9CD651;
    }
`;
